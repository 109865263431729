import { GAccountData } from '@/types/types';
import axios from 'axios';
import { OAUTH2_CONSTANTS } from '@/constants/Oauth2Endpoints';

class AuthService {
    setAccessToken(token: string) {
        localStorage.setItem('token', token);
    }

    setRefreshToken(token: string) {
        localStorage.setItem('refresh_token', token);
    }

    getAccessToken() {
        return localStorage.getItem('token');
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    removeAccessToken() {
        localStorage.removeItem('token');
    }

    removeRefreshToken() {
        localStorage.removeItem('refresh_token');
    }

    removeAllTokens() {
        this.removeAccessToken();
        this.removeRefreshToken();
    }

    parseToken(): GAccountData | null {
        const token = this.getAccessToken();
        if (!token) {
            return null;
        }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    isTokenExpired(): boolean {
        const token = this.getAccessToken();
        if (!token){
            return true;
        }

        const payload = this.parseToken();
        if (!payload || !payload.exp){
            return true;
        }

        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp <= currentTime;
    }

    async ensureAccessToken() {
        if (this.isTokenExpired()) {
            await this.refreshAccessToken();
        }
    }

    async refreshAccessToken() {
        const clientId = OAUTH2_CONSTANTS.clientId;
        const clientSecret = OAUTH2_CONSTANTS.clientSecret;
        const tokenEndpoint = OAUTH2_CONSTANTS.tokenEndpoint;
    
        try {
          const response = await axios.post(tokenEndpoint, {
            client_id: clientId,
            client_secret: clientSecret,
            refresh_token: this.getRefreshToken(),
            grant_type: 'refresh_token',
          });
    
          const { id_token } = response.data;
    
          this.setAccessToken(id_token);
        } catch (error) {
          console.error('Error refreshing access token:', error);
          throw error;
        }
    }
}

export default new AuthService();
