
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import axios from 'axios';
import authService from '@/services/auth.service';
import { ref } from 'vue';
import router from '@/router';
import { accountModule } from '@/store/modules/account';
import { OAUTH2_CONSTANTS } from '@/constants/Oauth2Endpoints';

@Component({})
export default class LoginView extends Vue {
  clientId = OAUTH2_CONSTANTS.clientId;
  clientSecret = OAUTH2_CONSTANTS.clientSecret;
  redirectUri = window.location.origin;
  authEndpoint = OAUTH2_CONSTANTS.authEndpoint;
  tokenEndpoint = OAUTH2_CONSTANTS.tokenEndpoint;
  scope = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
  responseType = 'code';

  errorMessage = '';

  redirectToGoogleAuth() {
    const authUrl = `${this.authEndpoint}?` +
    `client_id=${this.clientId}&` +
    `redirect_uri=${encodeURIComponent(this.redirectUri)}&` +
    `response_type=${this.responseType}&` +
    `scope=${encodeURIComponent(this.scope)}&` +
    `access_type=offline&` +
    `prompt=consent`;

  const popup = window.open(authUrl, '_blank', 'width=500,height=600');

  const interval = setInterval(() => {
    if (popup && popup.closed) {
      clearInterval(interval);
      return;
    }

    try {
      const popupUrl = popup?.location.href || '';
      if (popupUrl.includes('?code=')) {
        const urlParams = new URLSearchParams(popup?.location.search);
        const authCode = urlParams.get('code');

        if (authCode) {
          popup?.close();
          clearInterval(interval); 
          this.exchangeCodeForTokens(authCode);
        }
      }
    } catch (error) {
    }
  }, 50); 
}

async exchangeCodeForTokens(authCode: string) {
    try {
        const response = await axios.post(this.tokenEndpoint, {
            code: authCode,
            client_id: this.clientId,
            client_secret: this.clientSecret,
            redirect_uri: this.redirectUri,
            grant_type: 'authorization_code',
        });
        
        const { refresh_token, id_token } = response.data;

        authService.setAccessToken(id_token);
        authService.setRefreshToken(refresh_token);
        this.getLoginDetails();
    } catch (error) {
        console.error('Error exchanging code for tokens:', error);
        this.errorMessage = 'Failed to authenticate. Please try again.';
    }
 }

  handleOAuthCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');

    if (authCode) {
      window.history.replaceState({}, document.title, this.redirectUri);

      this.exchangeCodeForTokens(authCode);
    }
  }

  renderGoogleButton() {
  const signInButton = document.getElementById('signin_button');

  if (signInButton) {
    signInButton.innerHTML = `
      <img 
        src="https://developers.google.com/identity/images/btn_google_signin_light_normal_web.png" 
        alt="Sign in with Google"
        class="google-login-img"
        style="cursor: pointer;"
      />
    `;
    signInButton.querySelector('img')?.addEventListener('click', this.redirectToGoogleAuth.bind(this));
  }
}


  async getLoginDetails() {
    try {
      const response = await accountModule.getAccount();
      if (response.status === 200) {
        if (this.$route.query.redirect) {
          router.push(`${this.$route.query.redirect}`);
        } else {
          router.push('/');
        }
      }
    } catch (error) {
      console.error('Error fetching login details:', error);
        this.errorMessage = 'An error occurred. Please try again later or contact the administrator.';
    }
  }

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
      this.handleOAuthCallback(); 
    } else {
      this.renderGoogleButton(); 
    }
  }
}
